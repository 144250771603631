<div class="footer">
        <div class="footer_redes" >
            <a class="icon bckg_icon_face" target="_blank" href="https://www.facebook.com/people/Clover-Venezuela/100092613104079/">
                &nbsp;
            </a>
            <a class="icon bckg_icon_ig" target="_blank" href="https://www.instagram.com/cloverfilevzla/">
                &nbsp;
            </a>
            <a class="icon bckg_icon_linke" target="_blank" href="https://www.linkedin.com/company/cloverfile/?originalSubdomain=ve">
                &nbsp;
            </a>
          </div>

            <div class="soport_contact">
                <div class="content_icons">
                <a class="icon bckg_icon_email" target="_blank" href="">
                    &nbsp;
                </a>
                <a class="icon bckg_icon_email" target="_blank" href="">
                    &nbsp;
                </a>
            </div>
                <span>
                    soporteservicios&#64;cloverve.com 
                    <br><span>+58 414-4708354</span>
                </span></div>
                
<div class="footer_derechos"><p class="footer_derechos_text">©2024 CloverFile es una unidad de Clover Internacional, C.A. RIF J-00042000-9. Todos los derechos reservados.</p></div>
</div>