import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs"; 

export class Message {
  constructor(public author: string, public content: string) {} 
}

@Injectable({providedIn: 'root'})
export class ChatbotService {
  audioFile = new Audio(
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/success.mp3"
  );
  messageContainer: any;
  constructor() {}

  conversation = new Subject<Message[]>();

  messageMap:any = {
    /////QUE ES UN PRESTAMO DOCUMENTAL/////
    '¿Qué se entiende por préstamo documental?': "Es un servicio que brinda CloverFile para poner a disposición de sus clientes, las cajas y/o expedientes documentales que son resguardados en nuestros almacenes.", 
    /////SEGUNDA DE PRESTAMO DOCUMENTAL/////
    'Quiero saber más': "Los prestamos documentales están asociados a las necesidades específicas de nuestros clientes, por lo cual nuestro equipo de Operaciones Física, están en la disposición de atender oportunamente sus solicitudes.",


    /////COMO CREO UNA SOLICITUD DE PRESTAMO DOCUMENTAL/////
    '¿Cómo creo una solicitud de préstamo documental?': "En la solución automatizada creada por CloverFile, podrá registrar su solicitud de préstamo, haciendo clic en la opción Nueva Solicitud, en dicho formulario se desprenderán los campos que permiten describir el préstamo, la duración y la modalidad de despacho.",
    /////SEGUNDA DE SOLICITUD DE PRESTAMO DOCUMENTAL/////
    '¿Puedo saber más?': "Por supuesto! Te explico de manera detallada los pasos para generar una solicitud Ingresa a la pagina web de CloverFile, y en la parte superior izquierda pulsa clic en la opción Solicitudes.Selecciona la opción Clientes y de allí se despliega la opción de Bienvenida al Sistema de Préstamo Documental.Ingresa el usuario y la contraseña que le fueron suministrados al momento de la habilitación del servicio. Pulsa nueva solicitud y llenalos campos correspondientes. Luego pulsa la opción aceptar y aparecerá un mensaje indicando que su solicitud de préstamo ha sido registrada satisfactoriamente. El sistema le informará a su correo electrónico la trazabilidad en los cambios de estatus.",


    /////SOLICITAR DIFERENTES DOCUMENTOS/////
    '¿Cómo puedo solicitar diferentes documentos en una solicitud de préstamo documental?': "El sistema está diseñado para que usted pueda seleccionar diferentes cajas, de acuerdo con la relación de almacenamiento y/o inventario.",
    /////SEGUNDA DE SOLICITAR DIFERENTES DOCUMENTOS/////
    'Quiero saber más de esto': "En la opción Nueva Solicitud, en la sección Describa el préstamo, indique de las cajas seleccionadas, cuales documentos o expedientes requiere, es importante que identifique el numero de la caja y el identificador del expediente, repita este proceso tantas veces sea necesario con el suficiente detalle.",


    /////MODALDADES DE DESPACHO/////
    '¿Cuáles son las modalidades de despacho de préstamo documental?': "<p>CloverFile pone a disposición de nuestros clientes y usuarios, tres modalidades de préstamo para adaptar nuestro servicio a sus necesidades:</p><ol><li><strong>Entrega en almacén</strong> </li><li><strong>Entrega en la oficina del cliente</strong> </li><li><strong>Digitalización</strong> </li></ol>",
    /////SEGUNDA DEMODALDADES DE DESPACHO/////
    'Explicame un poco más': "<p>CloverFile pone a disposición de nuestros clientes y usuarios, tres modalidades de préstamo para adaptar nuestro servicio a sus necesidades:</p><ol><li><strong>Entrega en almacén:</strong> esta modalidad le permite retirar sus cajas y/o expedientes en nuestras instalaciones. El equipo de Operaciones lo recibirá de acuerdo con la fecha u hora que haya seleccionado en su registro.</li><li><strong>Entrega en la oficina del cliente:</strong> en esta modalidad, el equipo de mensajería le hará llegar a sus oficinas las cajas y/o expedientes solicitados en calidad de préstamo, entregando los mismos a las personas autorizadas.</li><li><strong>Digitalización:</strong> esta alternativa permite solicitar la digitalización asistida de un documento y/o expediente, con el fin de que el mismo sea publicado en la nube y compartido el enlace vía correo electrónico, a las personas autorizadas.</li></ol>",


    /////Salir/////
    '¡Gracias!': "A la orden! Espero te hayan servido mis respuestas, si sigues con alguna duda te invito a que nos envíes un correo a nuestra dirección soporteservicios@cloverve.com ¡Hasta la próxima!",
  

    default: "Esa no es una opción válida. Por favor inténtalo nuevamente :)", 
  };

  getBotAnswer(msg: string) {
    const userMessage = new Message("user", msg);
    this.conversation.next([userMessage]);
    const botMessage = new Message("bot", this.getBotMessage(msg));
  

    setTimeout(() => {
      this.conversation.next([botMessage]);
    }, 1500);
  }
 
 

  getBotMessage(question: string) {
    let answer = this.messageMap[question];
    return answer || this.messageMap["default"];
  }
 
}
