import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdminService {
  private URL_API= environment.Api

  constructor(private http: HttpClient, private cookieService: CookieService)  {}

  GetUserRegister(){
    return this.http.post(this.URL_API+'/getUsers', {TokenTemp: this.cookieService.get('Token')});
  }

  SetUser(ID:any,Estado:any ){
    return this.http.post(this.URL_API+'/updateSession', {ID:ID ,sesion:Estado,TokenTemp: this.cookieService.get('Token')});
  }

  
  Getidentifier(Value:any){
    return this.http.post(this.URL_API+'/register', {Razon_social:Value.email[0] ,Correo_user:Value.correo, Username:Value.userName,Token:Value.password,ID_cliente:Value.email[1],ID_nube:Value.email[2],Person_Auth:Value.Autorizado});

  }
SendEmail(Value:any){
  const nombreDestinatario = Value.email[0];
  const usuarioAsignado = Value.userName;
  const contraseñaTemporal = Value.password;
//const emailClient = Value.correos;
const mensaje = `
<html>
  <body>
    <p>Estimado ${nombreDestinatario},</p>
    <p>Este correo es para notificarle que se le ha asignado un usuario dentro de nuestro sistema de préstamo documental, CloverFile. A continuación, encontrará las credenciales de acceso:</p>
    <ul>
      <li><strong>Usuario:</strong> ${usuarioAsignado}</li>
      <li><strong>Contraseña temporal:</strong> ${contraseñaTemporal}</li>
    </ul>
    <p><a href="https://servicioscloverfile.com/rrt">Ingrese aquí para acceder al sistema</a></p>
    <p>Una vez inicie sesión, el sistema le solicitará que cambie esta contraseña temporal por una propia.</p>
    <p>Por favor, utilice estas credenciales para acceder al sistema. Si tiene alguna pregunta o necesita asistencia, no dude en contactarnos.</p>
    <p>¡Gracias por confiar en CloverFile!</p>
    <p>Atentamente,<br>El equipo de CloverFile</p>
  </body>
</html>
`;
  return this.http.post(this.URL_API+'/email', {to:"sharepoint.clover@cloverve.com",subject:"raristimuno@cloverve.com",message: mensaje});
}
SendEmailPass(Value:any,valuecorreo:any){
  const usuarioAsignado = Value;
  const contraseñaTemporal = Math.random().toString(36).substring(2);
 const emailClient = valuecorreo;
 localStorage.setItem('User',usuarioAsignado)
 const mensaje = `
 <html>
   <body>
     <p>Este correo es para notificarle que hemos recibido una notificación de olvido de contraseña de su cuenta en nuestro sistema de préstamo documental, CloverFile. A continuación, encontrará su contraseña temporal:</p>
     <ul>
       <li><strong>Usuario:</strong> ${usuarioAsignado}</li>
       <li><strong>Contraseña temporal:</strong> ${contraseñaTemporal}</li>
     </ul>
     <p><a href="https://servicioscloverfile.com/rrt">Ingrese aquí para acceder al sistema</a></p>
     <p>Una vez inicie sesión, el sistema le solicitará que cambie esta contraseña temporal por una propia.</p>
     <p>Por favor, utilice estas credenciales para acceder al sistema. Si tiene alguna pregunta o necesita asistencia, no dude en contactarnos.</p>
     <p>¡Gracias por confiar en CloverFile!</p>
     <p>Atentamente,<br>El equipo de CloverFile</p>
   </body>
 </html>
 `;
 return this.http.post(this.URL_API+'/Forget_password', {password_temp:contraseñaTemporal,Username:usuarioAsignado}).pipe(
  switchMap(() => this.http.post(this.URL_API+'/email', {to:"sharepoint.clover@cloverve.com",subject:"raristimuno@cloverve.com",message: mensaje}))
);
}
  GetBusiness(){

    return this.http.post(this.URL_API+'/getClients', {TokenTemp: this.cookieService.get('Token')});
  }
  GetPersonsAuth(){

    return this.http.post(this.URL_API+'/getPersonsAuth', {TokenTemp:this.cookieService.get('Token')}).pipe(map((res: any) => {
      return { PersonsAuth: res[0].Persons_Contact };
    }));
    
  }
  GetEmails(Value:any){
    return this.http.post(this.URL_API+'/getEmails', {Username: Value}).pipe(
      map((res: any) => {
        return { CORREO: res[0].Correo_user };
      })
    );
  }
}
