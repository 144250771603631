import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SharepointService {

  Cajas:any = []
  Bitacora:any = []
  LogsBitacora:any = []
  LogsBitacoraCB: any = [];
  private URL_API= environment.Api
  
  constructor(private http: HttpClient,private cookieService: CookieService)  {


    this.http.post(this.URL_API+'/getcajas', {TokenTemp: this.cookieService.get('Token')}).subscribe(res=>{
      this.Cajas= res
      });
      
    this.http.post(this.URL_API+'/getLogChatBot', {TokenTemp: this.cookieService.get('Token')}).subscribe(res=>{
      this.LogsBitacoraCB= res })
      
    this.http.post(this.URL_API+'/getLogs', {TokenTemp: this.cookieService.get('Token')}).subscribe(res=>{
      this.LogsBitacora= res 
    });
  }

  

   Bitaco(){
   return this.http.post(this.URL_API+'/getrequests',{TokenTemp: this.cookieService.get('Token')});
    }

   Logout(){
    return this.http.post(this.URL_API+'/deleteItem', {TokenTemp: this.cookieService.get('Token')});
  }

  
  

}
