<html> 
    <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
    </head>      
    <body> 
        <!--ICONO DEL CHATBOT ANTES DE IR A LA MENSAJERIA*/-->
        <div class="icon" id="chatbotIcon" (click)="this['toggleChatbot']()" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
            <img id="defaultIcon" src="./assets/logoclover.png" class="logoclover">
            <img id="hoverIcon" src="./assets/icon.png" class="icono2">
            <div class="tooltip"> Hola, soy <b>Trébol</b>. ¿Puedo ayudarte? 😃</div>
        </div>
        <!--Toooooodooooooo*/-->
            <div class="cuadro" id="chatbot" style="display: none"> 
                <!--BOTON DE ABRIR Y CERRAR EL BOT*/-->
                <div>
                    <div class="botonsote1">
                        
                        <a href="https://www.cloverfileve.com/"> <img id="cloverfile" src="./assets/cloverfile.png" class="cloverfile"> </a>
                        <button (click)="this['toggleInfo']()"  class="botonsote" (click)="this['closeChatbot']()" id="chatbot">
                            <b>  X </b>
                        </button>
                    </div>

                    <!--IMAGENES QUE SE VEN CUANDO ABRES EL MENU*/-->
                    <div class="barrabot"> <!--barra verde/-->
                        <div class="imgbot"> <!--imagen/-->
                            <img id="logobot" src="./assets/circulobot.png">  
                        </div>
                    </div>

                    <div class="presentacion">
                         Hola, soy <b>Trébol</b>. <br>
                        Bienvenido/a al sistema de préstamo documental. <br>
                        Estos son algunos puntos de información en los que te puedo guiar: 
                    </div>

                </div>
                <!--CHAAAAAAAAAT CON MENSAJES PREESTABLECIDOS*/-->
                <div class="todito" id="messageContainer">
                    <ng-container class="todito">
                        <br>
                        <div id="div1" class="message-main" (click)="this['handleClick']('div1')">
                            <img id="prestamo" src="./assets/prestamo.png" class="iconomain">
                            Préstamo documental
                        </div>
                        <div id="div2" class="message-main" (click)="this['handleClick']('div2')">
                            <img id="crearprestamo" src="./assets/crearprestamo.png" class="iconomain">
                            Crear solicitud de préstamo documental
                        </div>
                        <div id="div3" class="message-main" (click)="this['handleClick']('div3')">
                            <img id="crearprestamo" src="./assets/distintascajas.png" class="iconomain">
                            Solicitud de diferentes documentos
                        </div>
                        <div id="div4" class="message-main" (click)="this['handleClick']('div4')">
                            <img id="crearprestamo" src="./assets/archivo.png" class="iconomain">
                            Modalidades de despacho de los préstamos
                        </div>

                    </ng-container>

                    <!--MENSAJES DEL USUARIO Y DEL BOT*/-->
                    <ng-container *ngFor="let message of messages">
                        
                        <div class="message" [ngClass]="{ 'from': message.author === 'bot', 'to': message.author === 'user' }">
                            <div [innerHTML]="message.content"></div>
                        </div>
                    </ng-container>

                    <!--BOTONES EXTRAS DE CADA DIV*/-->
                    <button *ngIf="selectedDivId === 'div1'" class="btnextra button-show" (click)="handleClick('morediv1')">Quiero saber más</button>
                    <button *ngIf="selectedDivId === 'div1'" class="btnextra2 button-show" (click)="handleClick('¡Gracias!')" >¡Gracias!</button>

                    <button *ngIf="selectedDivId === 'div2'" class="btnextra button-show" (click)="handleClick('morediv2')">¿Puedo saber más?</button>
                    <button *ngIf="selectedDivId === 'div2'" class="btnextra2 button-show" (click)="handleClick('¡Gracias!')">¡Gracias!</button>
                    
                    <button *ngIf="selectedDivId === 'div3'" class="btnextra button-show" (click)="handleClick('morediv3')">Quiero saber más de esto</button>
                    <button *ngIf="selectedDivId === 'div3'" class="btnextra2 button-show" (click)="handleClick('¡Gracias!')">¡Gracias!</button>
                    
                    <button *ngIf="selectedDivId === 'div4'" class="btnextra button-show" (click)="handleClick('morediv4')">Explicame un poco más</button>
                    <button *ngIf="selectedDivId === 'div4'" class="btnextra2 button-show" (click)="handleClick('¡Gracias!')">¡Gracias!</button>
                    
                    <button *ngIf="selectedDivId === 'div5'" class="btnextra button-show" (click)="handleClick('morediv5')">¿Puedes decirme más?</button>
                    <button *ngIf="selectedDivId === 'div5'" class="btnextra2 button-show" (click)="handleClick('¡Gracias!')">¡Gracias!</button>
                    
                    <button class="salirextra" (click)="handleClick('¡Gracias!')"> ¡Gracias!</button>
                </div>
                <!--BOTON DE ENVIAR MENSAJE AL BOT*/-->

                <div  class="botonsote2" >  
                    <input id="inputEnviar" [(ngModel)]="value" type="text" class="input" (keyup.enter)="handleClick(value)">
                    <img (click)="handleClick(value)" id="enviarmsg" src="./assets/enviarmsg.png"  class="enviarmsg">
                </div>
            </div>
    </body>
</html>