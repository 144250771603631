  <div class="formu" nz-col nzSpan="12" nzOffset="6">

    <form   [formGroup]="validateForm"  (ngSubmit)="submitForm()">
    
        <nz-divider class="cabecera" nzText="Detalles de solicitud"></nz-divider>
        
       

            <nz-form-item >

              <nz-form-control nzErrorTip="Por favor seleccione una caja!">
                <div class="Selected_cajas">
                  <label for="cajas"><b>Tipo de solicitud *</b></label>
                  <nz-select formControlName="TypeResq" nzShowSearch nzAllowClear nzPlaceHolder="Tipo de solicitud">
                    <nz-option nzLabel="Caja" nzValue="Caja"></nz-option>
                </nz-select>
              </div>
              </nz-form-control>
          
          </nz-form-item> 
              

            <nz-form-item >
              <nz-form-control nzErrorTip="Por favor seleccione una caja!">
                <div class="Selected_cajas">
                <label for="cajas"><b>Seleccione las cajas para el préstamo *</b></label>
                <nz-select formControlName="cajas"
                  [nzMaxTagCount]="3"
                  [nzMaxTagPlaceholder]="tagPlaceHolder"
                  nzMode="multiple"
                  nzPlaceHolder="Seleccione las cajas para el préstamo"
                  [(ngModel)]="listOfSelectedValue"
          
                  
                  >
                    <nz-option *ngFor="let item of ServicioRRT.Cajas" [nzLabel]="item.Identificador" [nzValue]="item.ID_caja"></nz-option>
                </nz-select>
              </div>
              </nz-form-control>
            </nz-form-item>
              <ng-template #tagPlaceHolder let-selectedList>y {{ selectedList.length }} más seleccionada</ng-template>
  
            

            <nz-form-item>
              <nz-form-control nzErrorTip="Por favor seleccione una caja!">
                <div class="Selected_cajas">
                  <label for="cajas"><b>Describa el préstamo *</b></label>
                <textarea formControlName="descripcion"  nz-input placeholder="Describa el préstamo (información de la caja, expediente o documento de su solicitud)" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
              </div>
              </nz-form-control>
            </nz-form-item>



        <nz-divider nzText="Periodo del Préstamo"></nz-divider>

        <nz-form-item>
          <nz-form-control nzErrorTip="Por favor seleccione una caja!">
            <div class="Selected_cajas">
            <div class="Selected_cajas">
           
              <label for="cajas"><b>Días establecídos para el préstamo</b></label>
              <nz-input-number [nzMin]=1 [nzStep]="1" formControlName="periodo" [nzPlaceHolder]="'Días establecídos para el préstamo'" style="width: 100%" [nzDisabled]="isDisabled"></nz-input-number>
            </div>
            <div class="Indefinido_checkbox">
              <input formControlName="indefinido" type="checkbox" id="indefinido" name="indefinido" (click)="toggleInput()">
              <label for="indefinido"> Indefinido</label>
            </div>
          </div>
          </nz-form-control>
        </nz-form-item>        


        <nz-divider nzText="Modalidad de Despacho"></nz-divider>
      
              <nz-form-item>
                <nz-form-control nzErrorTip="Por favor seleccione una caja!">
                  <div class="Selected_cajas">
                    <label for="cajas"><b>Seleccione la Modalidad *</b></label>
                  <nz-select formControlName="retiro" nzShowSearch nzAllowClear nzPlaceHolder="Seleccione la Modalidad" [(ngModel)]="selectedValue" (ngModelChange)="provinceChange($event)">
                      <nz-option nzLabel="Retiro en almacén CloverFile" nzValue="Macarao"></nz-option>
                      <nz-option nzLabel="Entrega en la oficina del cliente" nzValue="Cliente"></nz-option>  
                      <nz-option nzLabel="Envío electrónico (Digitalizado)" nzValue="Digitalización"></nz-option>  
                  </nz-select>
                </div>
                </nz-form-control>
              </nz-form-item>

            


          <div class="Contentgroup"[ngSwitch] ='true'>
            <ng-template  [ngSwitchCase]="selectedValue==='Cliente' || selectedValue==='Macarao'">
              <div class="Contentgroup" *ngIf="selectedValue==='Macarao'">
                  <nz-form-item>
                    <nz-form-control nzErrorTip="Por favor seleccione una caja!">
                      <div class="Selected_cajas">
                        <label for="cajas"><b>Seleccionar fecha *</b></label>
                        <nz-date-picker
                        nzShowTime
                        nzFormat="dd-MM-yyyy HH:mm:ss"
                    

                        formControlName="Date_retiro" 
                        
                      ></nz-date-picker>
                    </div>
                    </nz-form-control>
                  </nz-form-item>
              </div>

              <nz-form-item>
                <nz-form-control nzErrorTip="Por favor rellene este campo!">
                  <div class="Selected_cajas">
                    <label for="cajas"><b>Persona Autorizada * </b></label>
                  <nz-select [(ngModel)]="selectedValue2" formControlName="PersonaAutorizada" nzShowSearch nzAllowClear nzPlaceHolder="Seleccione la Persona Autorizada">
                    <nz-option *ngFor="let item of personsAuth" [nzLabel]="item" [nzValue]="item" ></nz-option>
                </nz-select>
                </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-control nzErrorTip="Por favor seleccione una caja!">
                <div class="Indefinido_checkbox">
                  <input type="checkbox" id="indefinido" name="Persons" (click)="viewpersons()">
                  <label for="Persons">Autorizar a otra persona para el retiro</label>
                </div>
              </nz-form-control>
              <div *ngIf="isDisabled2" class="Contentgroup">
              <nz-form-item>
                
              </nz-form-item>   
                  <nz-form-item>
                    <nz-form-control nzErrorTip="Por favor rellene este campo!">
                      <div class="Selected_cajas">
                        <label for="cajas"><b>Nombre y apellido *</b></label>
                      <input formControlName="nombre" nz-input placeholder="Nombre y apellido"  type="text" required />
                      </div>
                    </nz-form-control>
                  </nz-form-item>

                      
                <nz-form-item>
                    <div class="Selected_cajas">
                      <label for="cajas"><b>Cédula</b></label>
                    <input formControlName="cedula" nz-input placeholder="Cédula"  type="number" />
                    </div>
                </nz-form-item>
              </div>
            
            </ng-template>
            

            <ng-template [ngSwitchCase]="selectedValue==='Digitalización'">
              <nz-divider nzPlain nzText="Digitalización asistida" nzOrientation="center"></nz-divider>
              <p  class="btn-wrappers"
              nz-flex
              [nzJustify]="'center'"
              [nzAlign]="'center'">
                Al seleccionar esta opción, la información solicitada será digitalizada y enviada al correo electrónico autorizado.
              </p>
            </ng-template>

          </div>
          <nz-button-group    
          class="btn-wrappers marg"
          nz-flex
          [nzJustify]="'center'"
          [nzAlign]="'center'">
            <button class="submit" nz-button [disabled]="!validateForm.valid" nzType="primary">Aceptar</button>
            <button class="delete" nz-button (click)="onSave($event)">Cancelar</button>
          </nz-button-group>

    </form>    
  </div>
  <router-outlet></router-outlet>
  <router-outlet name="footer"></router-outlet>