import { CommonModule, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Message, ChatbotService } from '../services/chatbot.service';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SocketService } from '../services/socket.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'my-app',
  standalone: true,
  imports: [NgClass, CommonModule, FormsModule, ReactiveFormsModule, RouterLinkActive, RouterLink, RouterOutlet],
  templateUrl: './chatbot.component.html',
  styleUrl: './chatbot.component.css'
})



export class ChatbotComponent implements OnInit {
  messages: Message[] = [];
  value!: string;
  Message: any;
  links: any;
localStorage: any;
  
  
 
  constructor(public chatbotService:  ChatbotService,  private notification: NzNotificationService) { }

  
  isInfoVisible = true;
  toggleInfo() {
    this.isInfoVisible = !this.isInfoVisible;
  }
   
  sendMessage() {
    this.chatbotService.getBotAnswer(this.response);
    this.response = '';
    this.scrollToBottom();
    let inputElement = document.getElementById('inputEnviar') as HTMLInputElement;
    inputElement.value = '';
  } 

  response!: string;
  selectedDivId!: string;
  showExtraButton = true;
  handleClick(divId: string) {
    this.selectedDivId = divId;
     // Aquí agregamos la lógica para mostrar el botón 'salirextra' cuando se hace clic en cualquier botón 'btnextra'
    if (divId.startsWith('morediv')) {
      let salirextraButton = document.querySelector('.salirextra') as HTMLElement;
      if (salirextraButton) {
        salirextraButton.style.display = 'block';
      }
    }
    if (divId === '¡Gracias!') {
      let salirextraButton = document.querySelector('.salirextra') as HTMLElement;
      if (salirextraButton) {
          salirextraButton.style.display = 'none';
      }
    }
    if (divId.startsWith('div')) {
      let salirextraButton = document.querySelector('.salirextra') as HTMLElement;
      if (salirextraButton) {
          salirextraButton.style.display = 'none';
      }
    }

    switch (divId) {

      /*PRIMERA PREGUNTA*/ 
      case 'div1':
      this.response = '¿Qué se entiende por préstamo documental?';
      break;
      case 'morediv1':
      this.response = 'Quiero saber más';
      break;


      /*SEGUNDA PREGUNTA*/ 
      case 'div2':
      this.response = '¿Cómo creo una solicitud de préstamo documental?';
      break;
      case 'morediv2':
      this.response = '¿Puedo saber más?';
      break;


      /*TERCERA PREGUNTA*/ 
      case 'div3':
      this.response = '¿Cómo puedo solicitar diferentes documentos en una solicitud de préstamo documental?';
      break;
      case 'morediv3':
      this.response = 'Quiero saber más de esto';
      break;

      /*CUARTA PREGUNTA*/ 
      case 'div4':
      this.response = '¿Cuáles son las modalidades de despacho de préstamo documental?';
      break;
      case 'morediv4':
      this.response = 'Explicame un poco más';
      break;

      /*QUINTA PREGUNTA*/ 
      case 'div5':
      this.response = 'Si ya registré un préstamo ¿Cómo puedo modificarlo?';
      break;
      case 'morediv5':
      this.response = '¿Puedes decirme más?';
      break;

      /*OPCION PARA DAR UNA DESPEDIDA*/ 
      case '¡Gracias!':
      this.response = '¡Gracias!';
      break;

      default:
      this.response = this.value;
    }
    this.sendMessage();
  }

  

  scrollToBottom() {
    setTimeout(() => {
      const chat = document.getElementById('messageContainer');
      if (chat) {
        chat.scrollTop = chat.scrollHeight;
      }
    });
  }  

  ngOnInit() {
    this.chatbotService.conversation.subscribe((val) => {
    this.messages = this.messages.concat(val);
    });
  } 


  toggleChatbot() {
    var chatbot = document.getElementById('chatbot');
    if (chatbot) {
      if (chatbot.style.display === 'none') {
        chatbot.style.display = 'block';
      } else {
        chatbot.style.display = 'none';
      }
    }else {
      console.log("No se encontró el elemento con id 'chatbot'");
    }
  }
  
  closeChatbot() {
    var chatbot = document.getElementById('chatbot');
    if (chatbot) {
      chatbot.style.display = 'none';
    }
  }

 
  onMouseOver() {
    let defaultIcon = document.getElementById('defaultIcon');
    let hoverIcon = document.getElementById('hoverIcon');
    if (defaultIcon && hoverIcon) {
      defaultIcon.style.display = 'none';
      hoverIcon.style.display = 'block';
    }
  }
  
  onMouseOut() {
    let defaultIcon = document.getElementById('defaultIcon');
    let hoverIcon = document.getElementById('hoverIcon');
    if (defaultIcon && hoverIcon) {
      defaultIcon.style.display = 'block';
      hoverIcon.style.display = 'none';
    }
  }  
  createNotification(type: string,Value:string,Titulo:string): void {
    
    this.notification.create(
      type,
      Titulo,
      Value
    );
  }
} 

export class AppComponent  {
  [x: string]: any;
  links = [
    {path:'/chatbot', label:'X', active:'button-active'},
  ]
} 

