import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { BehaviorSubject, Observable, catchError, debounceTime, map, of, switchMap } from 'rxjs';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { DemoNgZorroAntdModule } from '../../../../ngzorro-antd.module';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { FormControl, FormGroup, FormsModule, NonNullableFormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { SocketService } from '../../../../services/socket.service';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { SharepointService } from '../../../../services/sharepoint.service';
import { registerLocaleData } from '@angular/common';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import en from '@angular/common/locales/en';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { Router,RouterLink,RouterLinkActive, RouterOutlet } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';

registerLocaleData(en);

@Component({
  selector: 'app-prestamo',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    NzSelectModule,
    CommonModule,
    NzDatePickerModule,
    DemoNgZorroAntdModule,
    NzFlexModule,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputNumberModule,
    RouterLinkActive,
    NzCheckboxModule
  ],
  templateUrl: './prestamo.component.html',
  styleUrl: './prestamo.component.css'
})
export class PrestamoComponent implements OnInit {

  public ServicioRRT = inject(SharepointService)
  
  isDisabled = false;
  isDisabled2 = false;
toggleInput() {
  this.isDisabled = !this.isDisabled;
  if (this.isDisabled) {
    this.validateForm.controls['periodo'].setValue('Indefinido');
  } else {
    this.validateForm.controls['periodo'].setValue(null);
  }
}
viewpersons(){
  this.isDisabled2 = !this.isDisabled2;
}
  constructor(private fb: NonNullableFormBuilder,private requestBox:SocketService, private notification: NzNotificationService, private adminservice:AdminService) {}
  IDCAJA ='http://localhost:8080'
  searchChange$ = new BehaviorSubject('');
  optionList: string[] = [];
  selectedUser?: string;
  isLoading = false;
  isVisible = false;
  monitor = "";
  listOfOption: string[] = [];
  date = null;
  listOfSelectedValue:any[]=[];
  selectedValue2: any;
  personsAuth:any=[];
  ngOnInit(): void {
    this.adminservice.GetPersonsAuth().subscribe(res=>{
      this.personsAuth = res.PersonsAuth.split(",")
    });
   }
  


  validateForm: FormGroup<{
    TypeResq: FormControl<any>;
    cajas: FormControl<any>;
    descripcion: FormControl<any>; 
    periodo: FormControl<any>;
    retiro: FormControl<any>;
    Date_retiro: FormControl<any>;
    nombre: FormControl<any>;
    cedula: FormControl<any>;
    PersonaAutorizada: FormControl<any>;
    indefinido: FormControl<any>;
    
  }> = this.fb.group({
    TypeResq: ['',[Validators.required]],
    cajas: [[''],[Validators.required]],
    descripcion: ['',[Validators.required]],
    periodo: ['',[Validators.required]],
    retiro: ['',[Validators.required]],
    Date_retiro: [new Date(),[Validators.required]],
    nombre: ['',],
    cedula: ['',],
    PersonaAutorizada: ['',[Validators.required]],
    indefinido: ['',]
  });

  submitForm(): void {
    if (this.validateForm.valid) { 
    
      this.requestBox.RestBox(this.validateForm.value).subscribe((res:any)=>{
        if(res.status===1){
          this.createNotification('success',res.message,'Solicitud')
          this.validateForm.reset();
        }
        else (this.createNotification('error',res.message,'Solicitud'))
        });
        
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }



  provinceChange(prueba:any){
    if(prueba === "Macarao"){this.isVisible=true; this.monitor="Macarao";}
    else if (prueba === "Cliente") {this.isVisible=true;this.monitor="Cliente";}
    else if(prueba===null ){this.isVisible=false;}
    
  }
  onSave(event:any): void {
    this.validateForm.reset();

    
  if (this.isDisabled) {
    this.isDisabled = false;
    this.validateForm.controls['periodo'].setValue(null);
  } else {
    this.isDisabled = false;
    this.validateForm.controls['periodo'].setValue(null);
  }
  }


  selectedValue = null;
  
  createNotification(type: string,Value:string,Titulo:string): void {
    
    this.notification.create(
      type,
      Titulo,
      Value
    );
  }

  


}
